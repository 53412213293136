import React from "react";
import "./Footer.css";
import Logo from '../../assets/imgs/sinau.png';

const Footer = ({ handleNavigationBtn, menuItems, socialLinks }) => {
  return (
    <footer className="footer-background">
      <div className="footer-container">
        <div className="footer-logo">
          <img src={Logo} alt="logo"></img>
          <p>
            Segurança veicular é com a Potiguar Inspeções.
            Qualidade comprovada
          </p>
        </div>

        <div className="footer-item">
          <h5>NAVEGAÇÃO</h5>
          <div>
            {menuItems.map((item, index) => (
              <li key={index}>
                <button onClick={() => handleNavigationBtn(item)}>
                  {item.label}
                </button>
              </li>
            ))}
          </div>
        </div>

        <div className="footer-item">
          <h5>ESPECIALIDADES</h5>
          <div className="especialidades">
            <p>Inspeções de Segurança Veicular</p>
            <p>Inspeção Periódica GNV</p>
            <p>Descontaminação</p>
            <p>Instalação Inicial de GNV</p>
            <p>Laudo Técnico</p>
          </div>
        </div>

        <div className="footer-item">
          <div>
            <h5>CONTATO</h5>
            <p>Email: financeiro@potiguarinspecoes.com.br ou potiguarinspecoes@hotmail.com</p>
            <p>Telefone: (84) 3605-9777 ou (84) 98722-9777</p>
          </div>
          <div>
            <h6>ENDEREÇO</h6>
            <p>Av. Jerônimo Câmara, 1096 - Nossa Sra. de Nazaré, Natal - RN</p>
            <p>CEP: 59060-300</p>
          </div>
        </div>
      </div>

      <div className="footer-copy">
        <p>&copy; 2024 Potiguar Inspeções. Todos os direitos reservados.</p>
        <div className="footer-socials">
          {socialLinks.map((link, index) => (
            <a key={index} href={link.url} target="_blank" rel="noreferrer">
              <i className={`${link.icon}`} aria-label={link.label}></i>
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;


