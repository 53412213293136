import React from "react";
import "./Diferenciais.css";

function Diferenciais() {
  return (
    <div className="diferenciais-wrapper">
      <div className="diferenciais-container">
        <div>
          <i className="uil uil-award"></i>
          <h4>Referência no Segmento</h4>
          <p>
            A Potiguar Inspeções é reconhecida como referência no segmento de segurança veicular, comprometida em melhorar a qualidade de vida da população e preservar o meio ambiente através de nossos serviços de inspeção de alta qualidade.
          </p>
        </div>
        <div>
          <i className="uil uil-check-circle"></i>
          <h4>Missão e Compromisso</h4>
          <p>
            Nossa missão é prestar serviços de inspeção de segurança veicular com imparcialidade, eficiência, confidencialidade e qualidade, sempre prezando pela segurança dos nossos clientes e respeitando a legislação de trânsito, normas ambientais e regulamentações técnicas do Inmetro.
          </p>
        </div>
        <div>
          <i className="uil uil-users-alt"></i>
          <h4>Experiência e Expertise</h4>
          <p>
            Com mais de 20 anos de experiência no mercado, nossa equipe altamente capacitada oferece serviços de inspeção veicular com excelência. Atuamos garantindo confiança e honestidade em todas as etapas do processo.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Diferenciais;
