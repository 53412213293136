import React, { useState } from "react";
import "./Contato.css";
import MainBtn from "../MainBtn/MainBtn";

function Contato() {
  const [formData, setFormData] = useState({
    nome: "",
    sobrenome: "",
    email: "",
    celular: "",
    departamento: "",
    mensagem: "",
    origem: "Potiguar Inspeções"
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://jacquesgomes.com.br/inspecoesapi/contato/store", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Formulário enviado com sucesso!");
        setFormData({
          nome: "",
          sobrenome: "",
          email: "",
          celular: "",
          departamento: "",
          mensagem: "",
          origem: "Potiguar Inspeções"
        });
      } else {
        alert("Erro ao enviar o formulário. Por favor, tente novamente.");
      }
    } catch (error) {
      console.error("Erro ao enviar o formulário:", error);
      alert("Erro ao enviar o formulário. Por favor, tente novamente.");
    }
  };

  return (
    <div className="contato-wrapper">
      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3969.2469253177187!2d-35.230589089064196!3d-5.820760357370428!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7b25542613534cb%3A0x9d5fd73dabadefa7!2sPotiguar%20Inspe%C3%A7%C3%B5es%20de%20Seguran%C3%A7a%20Veicular!5e0!3m2!1spt-BR!2sbr!4v1726186036336!5m2!1spt-BR!2sbr"
          width="100%"
          height="400px"
          title="endereço"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>


      </div>

      <div className="contato-container">
        <div className="form-column">
          <h2>Fale conosco | Ouvidoria</h2>
          <p>
            Para solicitar informações adicionais, tirar dúvidas ou dar
            sugestões, envie-nos uma mensagem, que entraremos em contato o mais
            breve possível.
          </p>
          <form onSubmit={handleSubmit}>
            <div className="contato-flex">
              <div>
                <input
                  type="text"
                  name="nome"
                  placeholder="Nome"
                  value={formData.nome}
                  onChange={handleChange}
                />
              </div>
              <div>
                <input
                  type="text"
                  name="sobrenome"
                  placeholder="Sobrenome"
                  value={formData.sobrenome}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="contato-flex">
              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="E-mail"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div>
                <input
                  type="tel"
                  name="celular"
                  placeholder="Celular"
                  value={formData.celular}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div>
              <select
                name="departamento"
                value={formData.departamento}
                onChange={handleChange}
              >
                <option value="">Selecione o departamento</option>
                <option value="suporte">Suporte</option>
                <option value="vendas">Vendas</option>
                <option value="financeiro">Financeiro</option>
              </select>
            </div>
            <div>
              <textarea
                name="mensagem"
                placeholder="Escreva sua mensagem"
                value={formData.mensagem}
                onChange={handleChange}
              ></textarea>
            </div>
            <button className="main-btn alt" type="submit">Enviar</button>
          </form>
        </div>
        <div className="info-column">
          <h2>Potiguar Inspeções</h2>
          <p>Av. Jerônimo Câmara, 1096 - Nossa Sra. de Nazaré, Natal - RN</p>
          <p>CEP: 59060-300</p>
          <p>(84) 3605-9777 ou (84) 98722-9777</p>
          <p>financeiro@potiguarinspecoes.com.br ou potiguarinspecoes@hotmail.com</p>
          <p>Horário de funcionamento: Seg a Sex - 7h às 17h48</p>
          <div>
            <h3>Marque seu agendamento</h3>
            <p>
              Agende já a inspeção de veículos de cargas perigosas (para veículos
              de pequeno porte não é necessário agendamento).
            </p>
            <MainBtn alt={true} children="Fale Conosco" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contato;
