const socialLinks = [
    {
        url: 'https://www.instagram.com/potiguarinspecoes/',
        label: 'Instagram icon',
        icon: 'uil uil-instagram',
    },
    {
        url: "https://wa.me/5584987229777",
        label: 'Whatsapp Icon',
        icon: 'uil uil-whatsapp',
    },
];

export default socialLinks;

